<template>
  <tr>
    <td>
      {{ role._role }}
    </td>
    <td>
      {{ role._department }}
    </td>
    <td>
      <span :data="role.status" class="userRoleStatus">
        <i class="fas"></i>
        {{ role._status }}
      </span>
      <span
        v-if="role.isStatusModified"
        class="modifiedRoleIndicator"
        v-tooltip="'Statusul a fost modificat.'"
      ></span>
    </td>
    <td>
      <input type="checkbox" v-model="isConsilier">
    </td>
    <td v-if="showSelect" style="text-align: right;">
      <span class="userRoleActionBtn">
        <template v-if="role.actionBtn && !role._state">
          <i
            v-if="role.actionBtn === 1"
            @click="$emit('setRoleStatus', role.realIndex, true)"
            class="fas fa-check-circle"
            data="type-1"
            v-tooltip="'Activează rolul'"
          ></i>
          <template v-if="role.actionBtn === 2">
            <i
              v-if="canDisableRoles"
              @click="$emit('setRoleStatus', role.realIndex)"
              class="fas fa-exclamation-circle"
              data="type-2"
              v-tooltip="'Dezactivează rolul'"
            ></i>
            <i v-else class="fas fa-ban" style="cursor: default;" v-tooltip="'Ultimul rol poate fi dezactivat odată cu utilizatorul.'"></i>
          </template>
          <i
            v-if="role.actionBtn === 3"
            @click="$emit('resendEmail', role.realIndex)"
            class="fas fa-repeat"
            data="type-3"
            v-tooltip="'Retrimite confirmare email'"
          ></i>
        </template>
        <i
          v-else-if="role._state === 2"
          class="fas fa-times"
          style="color: red; cursor: default"
          v-tooltip="
            'Acest rol nu poate fi eliminat atâta timp cât este implicat în vreun proces de avizare.'
          "
        ></i>
        <i
          v-else-if="role._state === true"
          class="fas fa-sync fa-spin"
          style="pointer-events: none"
        ></i>
      </span>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    role: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    showSelect: {
      type: Boolean,
      default: true,
    },
    canDisableRoles: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isConsilier: {
      get() {
        return this.role.isConsilier?.model ?? false
      },
      set(val) {
        this.$set(this.role.isConsilier, 'modified', true)
        this.$set(this.role.isConsilier, 'model', !! val)
      }
    }
  }
}
</script>

<style lang="scss">
.modifiedRoleIndicator {
  display: inline-flex;
  align-items: center;
  width: 1rem;
  height: 1rem;
  background-color: #39f;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}
.userRoleStatus {
  font-weight: bold;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.05);

  &[data='unconfirmed'] {
    color: #f2da07;
    > i:before {
      content: '\f017';
    }
  }
  &[data='disabled'] {
    color: red;
    > i:before {
      content: '\f06a';
    }
  }
  &[data='active'] {
    color: green;
    > i:before {
      content: '\f058';
    }
  }
  &[data='in_process'] {
    font-style: italic;
  }
}
.userRoleActionBtn {
  > * {
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 0.5rem;
    }
    &:hover {
      color: rgb(175, 0, 0);
    }
    &[data='type-1']:hover {
      color: green;
    }
    &[data='type-2']:hover {
      color: red;
    }
    &[data='type-3']:hover {
      color: #39f;
    }
  }
}
</style>
