<template>
  <div>
    <template v-if="showSelect">
      <div class="form-flex form-adapt-2 form-noBottom">
        <div class="form-group">
          <b>Alege rolul</b>
          <v-select :options="rolesMap" :disabled="error" v-model="Inputs.role">
            <template #selected-option="item">
              {{ changeRoleView(item.label) }}
            </template>
            <template #option="item">
              {{ changeRoleView(item.label) }}
            </template>
          </v-select>
        </div>
        <div class="form-group">
          <b>{{ usersFromOrigin ? 'Instituția' : 'Alege instituția' }}</b>
          <v-select
            :options="institutionData"
            label="name"
            v-model="Inputs.institution"
            :disabled="
              error || usersFromOrigin || !Inputs.role || selectedRoleIndex == 1
            "
            :no-drop="usersFromOrigin"
            :clearable="!usersFromOrigin"
            :loading="!institutionData.length && !emptyInputs[0]"
          >
            <template #option="{ name }">
              <span class="v-select_li_custom">
                <span>{{ name }}</span>
                <i v-tooltip="name" class="fas fa-info-circle"></i>
              </span>
            </template>
          </v-select>
        </div>
        <div class="form-group">
          <b>Alege departamentul</b>
          <v-select
            :options="filteredDepartments"
            label="name"
            v-model="Inputs.department"
            :disabled="error || !Inputs.institution || selectedRoleIndex < 3"
            :loading="!departmentData.length && !emptyInputs[1]"
          >
            <template #option="{ name }">
              <span class="v-select_li_custom">
                <span>{{ name }}</span>
                <i v-tooltip="name" class="fas fa-info-circle"></i>
              </span>
            </template>
          </v-select>
        </div>
      </div>
      <div align="right">
        <div v-show="selectedState === 1" style="margin-top: 0.75rem">
          <div @click="addRole" class="Button1">
            <div class="icon">
              <img
                src="@/assets/media/for_table/circle_ADD_new_etap.svg"
                alt=""
              />
            </div>
            <span class="Btn_content"> Adaugă Rol </span>
          </div>
        </div>
        <div v-show="selectedState === 2">
          <p style="color: red; font-weight: bold; margin: 0.75rem 0 0">
            <i class="fas fa-exclamation-circle"></i> Acest rol deja este
            adăugat
          </p>
        </div>
      </div>
      <div
        class="hr1"
        :style="
          selectedState === false ? 'margin: 1.25rem 0;' : 'margin: 0.75rem 0;'
        "
      ></div>
      <h4 v-show="!sRoles.length" class="prettyH4" style="margin-top: 2.5rem">
        <template v-if="!error">
          <i class="fas fa-user-plus"></i>
          Adaugă roluri
        </template>
        <h6 v-else>Error</h6>
      </h4>
    </template>
    <div
      class="TBParent"
      style="max-height: 32rem !important;padding-right: 1rem !important;"
    >
      <table v-show="sRoles.length">
        <thead>
          <th>Rol</th>
          <th>Departament</th>
          <th>Status</th>
          <th>Acces de consilier</th>
          <th v-if="showSelect" style="width: 12rem;text-align: right;">
            Acțiuni
          </th>
        </thead>
        <tbody class="ignoreOdd">
          <table-row
            v-for="(role, i) in nonOriginRoles"
            :key="i"
            :role="role"
            :class="{ isOdd: !(i % 2) }"
            :showSelect="showSelect"
            :canDisableRoles="value.length > 1"
            @setRoleStatus="setRoleStatus"
            @resendEmail="resendEmail"
          />
          <template v-for="e in identidations">
            <tr>
              <td colspan="5">
                <div style="display: flex;align-items: center;">
                  <span style="font-weight: bold;margin-right: 1rem;">{{
                    e.institutionName
                  }}</span>
                  <div style="flex: 1;border-top: 1px dashed #ccc;"></div>
                </div>
              </td>
            </tr>
            <table-row
              v-for="(role, i) in originsGroups[e.id]"
              :key="role.id"
              :role="role"
              :class="{ isOdd: !(i % 2) }"
              :showSelect="showSelect"
              :canDisableRoles="value.length > 1"
              @setRoleStatus="setRoleStatus"
              @resendEmail="resendEmail"
            />
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scopes>
.center {
  text-align: center !important;
}
</style>

<script>
import {
  INSTITUTIONS,
  DEPARTMENT,
  USERS_CHECK_ROLE,
  RESEND_ROLE,
} from '@/api.js'
import { mapMutations } from 'vuex'
import TableRow from './TableRow'
import DialogModal from '@/components/DialogModal'

const dataReader = data => {
  if (Array.isArray(data?.data?.result) && data.data.result.length) {
    return data.data.result
  }
  return null
}
const roleIndexMap = {
  1: ['superadmin'],
  2: ['admin_institutie', 'sef_institutie', 'responsabil_achizitii_extern', 'responsabil_achizitii', 'supervizor_achizitii', 'responsabil_buget'],
}

const cacheTools = {
  key: 'e8ef49e743ad426bb874316c58f3a898',
  timeoutSeconds: 60,
  clear() {
    localStorage.removeItem(this.key)
  },
  checkObject(val) {
    return Object.prototype.toString.call(val) == '[object Object]'
  },
  checkIdFormat(id) {
    return Number.isInteger(id)
  },
  isObjEmpty: (obj = {}) => {
    for (const x in obj) return false
    return true
  },
  get() {
    let toReturn = {}
    try {
      let cache = JSON.parse(localStorage.getItem(this.key))
      if (this.checkObject(cache) && !this.isObjEmpty(cache)) {
        toReturn = cache
      } else {
        this.clear()
      }
    } catch (err) {
      this.clear()
    }

    return toReturn
  },
  check(id, returnTimeout) {
    if (!this.checkIdFormat(id)) return false
    const x = (this.get() || {})[id]
    const y = e => new Date(...(e ? [e] : []))
    if (x && y() < y(x)) {
      return returnTimeout ? y(x) - y() : false
    }

    return true
  },
  getTimeoutTime() {
    return +new Date() + this.timeoutSeconds * 1000
  },
  set(id) {
    if (!this.checkIdFormat(id)) return
    const prepare = this.get()
    prepare[id] = this.getTimeoutTime()

    localStorage.setItem(this.key, JSON.stringify(prepare))
  },
}
window['cacheTools'] = cacheTools

export default {
  props: {
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    showSelect: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  components: {
    TableRow,
  },
  data() {
    return {
      rolesMap: [
        'admin_institutie',
        'sef_institutie',
        'supervisor',
        'functionar',
        'responsabil_achizitii',
        'supervizor_achizitii',
        'responsabil_achizitii_extern',
        'responsabil_buget'

      ],
      institutionData: [],
      departmentData: [],
      id: this.makeid(10),
      Inputs: {
        role: null,
        institution: null,
        department: null,
      },
      emptyInputs: [true, true],
    }
  },
  computed: {
    usersFromOrigin() {
      return this.getUserRole() !== 'superadmin'
    },
    originsGroups() {
      const prepare = {}

      for (let e of this.identidations) {
        prepare[e.id] = this.originRoles.filter(i => i.institution?.id === e.id)
      }

      return prepare
    },
    identidations() {
      return this.removeArrDuplicatesWithkeys(
        this.originRoles.map(e => ({
          institutionName: e.institution?.name || '',
          id: e.institution?.id,
        }))
      )
    },
    originRoles() {
      return this.sRoles.filter(e => e.institution !== null)
    },
    nonOriginRoles() {
      return this.sRoles.filter(e => e.institution === null)
    },
    sRoles() {
      const x = [...this.value]
      const prepareFn = (e, i) => {
        const statusesMap = {
          in_process: ['În proces', 3],
          unconfirmed: ['Neconfirmat', 3],
          disabled: ['Dezactivat', 1],
          active: ['Activat', 2],
        }
        const findMap = (status, index) => {
          const find = statusesMap[status]
          return find ? find[index] : null
        }
        const status = e._isNewStatus?.status ?? e.status ?? null

        return {
          ...e,
          status,
          isStatusModified: status != e.status,
          realIndex: i,
          actionBtn: e._newCreated ? false : findMap(status, 1),
          _role: this.changeRoleView(e?.role),
          _department: e.department?.name || '',
          _status: findMap(status, 0),
        }
      }
      const prepare = x.map(prepareFn)

      return this.usersFromOrigin
        ? (id =>
            Number.isInteger(id)
              ? prepare.filter(e => e.institution?.id === id)
              : [])(this.getUserInstitution(true)?.id)
        : prepare
    },
    selectedRoleIndex() {
      const x = this.Inputs?.role
      if (!x) return false
      const find = Object.entries(roleIndexMap).find(e => e[1].includes(x))
      return find ? parseInt(find[0]) : 3
    },
    selectedState() {
      const x = Object.values(this.Inputs)
      let completedAllInputs = x.filter(e => e !== null).length - x.length
      if (this.selectedRoleIndex === 2) {
        completedAllInputs++
      } else if (this.selectedRoleIndex === 1) {
        completedAllInputs += 2
      }
      return completedAllInputs === 0
        ? this.checkCurrentSelected()
          ? 2
          : 1
        : false
    },
    filteredDepartments() {
      const id = this.Inputs.institution?.id
      if (!id) return []
      return this.departmentData.filter(e => e.institution?.id === id)
    },
    filteredvalue() {
      return this.sRoles.map(e =>
        [e.institution?.name, e.department?.name, this.changeRoleView(e?.role)]
          .filter(e => e)
          .join('<b>, </b>')
      )
    },
    saveForCLearData() {
      return this.usersFromOrigin ? ['institution'] : []
    },
  },
  methods: {
    ...mapMutations(['addWindow']),
    inputsChecker() {
      const x = this.Inputs
      const y = Object.keys(x)

      for (let i = y.length - 1; i >= this.selectedRoleIndex; i--) {
        if (!this.saveForCLearData.includes(y[i])) this.Inputs[y[i]] = null
      }
    },
    checkCurrentSelected() {
      const x = this.Inputs
      const y = this.sRoles
      return !!y.find(
        e =>
          e?.role === x?.role &&
          e.institution?.id === x.institution?.id &&
          e.department?.id === x.department?.id
      )
    },
    setRoleStatus(index, pos = false) {
      const role = this.value[index]
      if (!role) return

      if (role._isNewStatus) {
        this.$set(role, '_isNewStatus', false)
        return
      }

      const labelsMap = [
        {
          title: 'Activează rol',
          labels: ['Activat', 'activării', 'Observații'],
          button: { value: 'Activeaza', type: 2 },
        },
        {
          title: 'Dezactivează rol',
          labels: ['Dezactivat', 'dezactivării', 'Motivul'],
          button: { value: 'Dezactiveaza', type: 3 },
        },
        {
          title: 'Șterge rol',
          labels: ['Șters', 'ștergerii', 'Motivul'],
          button: { value: 'Șterge', type: 3 },
        },
      ]

      let isModalOpened = false
      const showModal = setState => {
        if (typeof setState != 'function') {
          setState = () => {}
        }

        isModalOpened = true
        this.addWindow({
          ...labelsMap[pos ? 0 : 1],
          rejectData: {},
          afterConfirm: data => {
            const comment = data?.rejectedComment ?? ''
            const status = {
              false: 'disabled',
              true: 'active',
            }[pos]
            const setNewStatus = () =>
              this.$set(role, '_isNewStatus', {
                comment,
                status,
              })

            setNewStatus()
            setState(false)
          },
          onClose() {
            setState(false)
          },
          modal: 11,
        })
      }

      if (pos) {
        showModal()
      } else {
        this.checkRole(
          role,
          showModal,
          setState => {
            this.$modal.show(
              DialogModal,
              {
                target: 'RoleDisable',
                title: `${'Dezactivează'} rol`,
                content:
                  '<span style="color:#f44336;display: block;margin-top: 2rem;"><i class="fas fa-exclamation-circle" style="font-size: 6rem;"></i><p style="font-weight: 500;font-size: 2.2rem;">Rolul selectat de dvs. poate fi aprobatorul unor necesități.</p></span>',
                closeBtnText: 'Anulează',
                button: {
                  type: 3,
                  value: 'Dezactiveaza',
                  handler: () => showModal(setState),
                },
              },
              {
                name: 'RoleDisable',
                adaptive: true,
                width: '650',
                height: '400',
              },
              {
                'before-close': function() {
                  window.requestAnimationFrame(() => {
                    if (!isModalOpened) {
                      setState(false)
                    }
                  })
                },
              }
            )
          },
          setState => setState(2)
        )
      }
    },
    checkRole(role, isOk, askIfIsOk, isNotOk) {
      if (!Number.isInteger(role?.id)) {
        return
      }
      if (typeof isOk != 'function') isOk = false
      if (typeof askIfIsOk != 'function') askIfIsOk = false
      if (typeof isNotOk != 'function') isNotOk = false

      const setState = state => this.$set(role, '_state', state)
      const error = msg => {
        this.$toastr.e(msg || 'Verificarea rolului a eșuat.')
        setState(false)
      }

      const callIfIsFn = (fn, ...params) =>
        typeof fn == 'function' ? fn(setState, ...params) : null

      setState(true)
      USERS_CHECK_ROLE(role.id)
        .then(e => {
          if (Array.isArray(e?.data?.result)) {
            const canDelete = !e.data.result.find(e => e.status == 'avizo')

            if (canDelete) {
              if (!e.data.result.length) {
                callIfIsFn(isOk)
              } else {
                callIfIsFn(askIfIsOk)
              }
            } else {
              callIfIsFn(isNotOk)
            }
          } else {
            error()
          }
        })
        .catch(error)
    },
    clearInputs() {
      Object.keys(this.Inputs).forEach(e => {
        if (!this.saveForCLearData.includes(e)) this.Inputs[e] = null
      })
    },
    addRole() {
      if (this.selectedState !== 1) {
        return
      }
      this.value.push({
        ...this.Inputs,
        status: 'in_process',
        isConsilier: {},
        _newCreated: true,
      })
      this.clearInputs()
    },
    loadInstitutions() {
      INSTITUTIONS()
        .then(data => {
          const x = dataReader(data)
          if (x) {
            this.institutionData = x
          } else {
            this.emptyInputs.splice(0, 1, true)
          }
        })
        .catch(err => {
          this.$toastr.e(err)
          this.emptyInputs.splice(0, 1, true)
        })
    },
    loadDepartments() {
      DEPARTMENT()
        .then(data => {
          const x = dataReader(data)
          if (x) {
            this.departmentData = x
          } else {
            this.emptyInputs.splice(1, 1, true)
          }
        })
        .catch(err => {
          this.$toastr.e(err)
          this.emptyInputs.splice(1, 1, true)
        })
    },
    loadData() {
      this.emptyInputs.fill(false)

      this.loadInstitutions()
      this.loadDepartments()
    },
    update(val) {
      this.$emit('input', val)
    },
    resendEmail(index) {
      const role = this.value[index]
      if (!Number.isInteger(role?.id)) return
      const check = cacheTools.check(role.id, true)
      if (check !== true) {
        this.$toastr.w(
          `Până la următoare trimitere de confirmare au mai rămas <b>${Math.floor(
            check / 1000
          )} secunde</b>.`
        )
        return
      }

      const setState = state => this.$set(role, '_state', state)
      const error = msg => {
        this.$toastr.e(
          'Retrimiterea de confirmare email a eșuat.' + (msg ? ` (${msg})` : '')
        )
        setState(false)
      }

      setState(true)
      RESEND_ROLE(role.id)
        .then(res => {
          if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
            this.$toastr.s('Confirmarea de email a fost retrimisă.')
            setState(false)

            cacheTools.set(role.id)
          } else {
            error()
          }
        })
        .catch(error)
    },
  },
  created() {
    this.update(this.value.map(e => ({ ...e, _state: false })))
    this.loadData()
    if (!this.usersFromOrigin) {
      this.rolesMap.unshift('superadmin')
    } else {
      this.Inputs.institution = this.getUserInstitution(true) || {}
    }

    this.$watch('Inputs.role', this.inputsChecker)
    this.$watch('Inputs.institution', () => {
      if (this.Inputs.department === null) {
        this.inputsChecker()
      } else {
        this.Inputs.department = null
      }
    })
    this.$watch('Inputs.department', this.inputsChecker)

    const sheet = document.createElement('style')
    sheet.id = `STYLE_${this.id}`

    sheet.innerHTML = `#I${this.id} .vs__selected-options {padding: 0 !important;}`
    document.body.appendChild(sheet)
  },
  beforeDestroy() {
    document.getElementById(`STYLE_${this.id}`)?.remove()
  },
}
</script>
